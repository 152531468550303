@import "../Assets/Functions.less";
@import (inline) "../Assets/Utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1199px)";
@desktop: ~"(min-width: 1200px)";
@mobileNavColor: #B62F16;
@castleDetailsColor: #c91d04;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/015/";
@cloudUrlNoAuto: "https://files.bookingonline.co.uk/image/upload/themes/015/";


/*#region Fonts*/
.OpenSans, .BodyFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.OpenSansemiBold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
}

.Montserrat {
	font-weight: 400 !important;
	font-family: 'Montserrat', sans-serif;
}

.MontserratBold {
	font-weight: 700 !important;
	font-family: 'Montserrat', sans-serif;
}

.Oswald {
	font-family: 'Oswald', sans-serif;
	font-weight: 400 !important;
}

.OswaldMedium {
	font-family: 'Oswald', sans-serif;
	font-weight: 500 !important;
}


.PTSansNarrowBold {
	font-family: 'PT Sans Narrow', sans-serif;
	font-weight: 700 !important;
}



html {
	.BodyFont;
	font-size: 16px;
}

body {
	overflow-x: hidden;
	background: #141d33;
}

.textContent {
	font-size: 1rem;
	color: #FFF;

	@media @mobile {
		text-align: center;
	}
}

.textContent h1 {
	font-size: 2.5rem;
	color: #FFF;
	.Oswald;
	text-transform: uppercase;
	text-align: center;
}

.lower-content{
	h2{

	}

	&--homepage{
		margin-top:-80px;
		margin-bottom:80px;
	}
}

.section-title {
	&--h2 {
		font-size: 2.5rem;
		color: #FFF;
		.Oswald;
		text-transform: uppercase;
	}

	&--h3 {
		.Montserrat;
		font-size: 1.125rem;
	}

	&--h4 {
	}


	&--newsletter {
		font-size: 1.8rem;
		.Oswald;
		color: #FFF;
	}

	&--testimonials, &--booth {
		color: #FFF;
	}

	&--products-intro {
		text-align: center;
	}
}
/*#endregion Fonts*/
#BodyContent {
	padding-top: 20px;

	@media @mobile {
		padding-top: 0;
	}
}

.nicefont, h1, h2, h3 {
	.MontserratBold;
}

form {
	margin: 0 auto;
	position: relative;
}

.outer-wrapper {
	max-width: 100vw;
	position: relative;

	@media @mobile {
		overflow: hidden;
	}

	&.outer-wrapper--alt {
		.top-wrapper {
			background: transparent;
		}

		.columns {
			margin-top: 0;
			justify-content: space-between;

			&__main {
				background: #fff;
				border-radius: 15px;
				margin-top: 20px;

				@media @tablet {
					width: 74.75%;
				}
			}
		}

		.columns__container {
			padding-top: 10px;
			min-height: 510px;
		}

		.textContent {
			color: #333 !important;

			h1 {
				color: #141D33 !important;
			}

			span {
				color: #333 !important;
			}
		}

		.lower-content h2 {
			color: #141D33 !important;
		}

		#BodyContent {
			@media @sm {
				padding-bottom: 50px;
			}
		}
	}
}

.container {
	position: relative;

	@media @mobile {
		margin: 0 auto 0;
		padding: 0;
	}

	@media @tablet {
		width: 100%;
		padding: 0 10px;
		margin: 0 auto 0;
	}

	@media @desktop {
		width: 1170px;
		margin: 0 auto 0;
	}
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
	padding-top: 53px;
}

#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	.Gradient(#e2533e,#c91e05);
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();
	min-height: 5px;

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	@media @sm {
		display: block !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0% 0%;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.top-wrapper {
	position: relative;
}

.header-wrapper {
	background-color: #141d33;
}

.header {
	@width: 2358px;
	@height: 444px;
	text-align: center;
	position: relative;
	background-color: #141d33;

	@media @mobile {
		padding: 20px 10px;
	}

	@media @tablet {
		padding-top: 17%;
	}

	&__container {
		padding: 0;
	}


	&__logo {
		@media @tablet {
			position: absolute;
			width: 28.93%;
			bottom: -22.52%;
			left: .42%;
		}

		@media @mobile {
			display: block;
			margin: 10px auto 20px;
			text-align: center;
			max-width: 80% !important;
		}
	}


	&__email {
		color: #fff;
		.Oswald;
		line-height: 1;
		font-size: 20px;

		&:before {
			margin-right: 15px;
			color: #3369e1;
			content: "\f0e0";
			font-family: FontAwesome;
		}

		&:hover {
			color: #fff;
		}

		@media @mobile {
			display: block;
			margin: 10px auto;
		}


		@media @tablet {
			position: absolute;
			bottom: 22.52%;
			left: 40%;
			text-align: left;
			font-size: 30px;
		}

		@media @desktop {
			font-size: 30px;
		}
	}

	&__phone, &__phone-2 {
		color: #fff;
		.Oswald;
		line-height: 1;
		font-size: 30px;

		&:before {
			margin-right: 15px;
			color: #3369e1;
			content: "\f095";
			font-family: FontAwesome;
		}


		&:hover {
			color: #fff;
		}

		@media @mobile {
			display: block;
			margin: 0 auto;
		}

		@media @tablet {
			position: absolute;
			bottom: 51.8%;
			left: 40%;
			text-align: left;
		}
	}



	.fa-phone {
		color: #3369e1;
		margin-right: 12px;

		@media @mobile {
			display: none;
		}
	}

	.fa-envelope {
		color: #3369e1;
		margin-right: 10px;

		@media @mobile {
			display: none;
		}
	}


	&__social-links {
		display: flex;
		justify-content: space-between;

		@media @mobile {
			margin: 20px auto 10px;
			max-width: 270px;
			justify-content: center;
		}

		@media @sm {
			position: absolute;
			width: 15%;
			bottom: -9%;
			right: auto;
			left: 39.5%;
			justify-content: flex-start;
		}

		@media @md {
			justify-content: flex-end;
			width: 14%;
			bottom: 45%;
			right: 0%;
			left: auto;
		}
	}

	&__social-link {
		@media @tablet {
			width: 24%;
		}

		@media @sm {
			margin: 0 2%;
		}

		@media @mobile {
			margin: 0 5px;
			max-width: 55px;
		}
	}

	.header__pipa {
		@media @tablet {
			position: absolute;
			top: 50%;
			width: 8%;
			right: 0;
		}
	}
}

.toplinks {
	position: relative;
	.PTSansNarrowBold;
	padding: 10px 0;

	&__wrapper {
		.Gradient(#e2533e,#c91e05);

		@media @sm {
			margin-top: 40px;
		}

		@media @md {
			margin-top: 0;
		}
	}

	&__container {
		padding: 0;
	}

	&__nav {
	}

	&__ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 30%;
	}

	&__li {
		text-align: center;
		list-style: none;
		position: relative;
		.Transition3s();
		flex-grow: 1;
		padding: 0;

		&.open .dropdown-toggle:focus {
			color: #fff;
		}
	}

	&__link {
		color: rgba(255,255,255,0.7);
		background: none;
		.transition(all 0.3s);
		white-space: nowrap;
		display: block;
		padding: 10px 5px;
		position: relative;
		z-index: 1;
		font-size: 1.2rem;
		.OswaldMedium;
		text-transform: uppercase;


		&:hover, &.active, &:focus {
			color: #FFF;
		}
	}

	&__dropdown, .dropdown-menu {
		.Gradient(#e2533e,#c91e05);
		text-align: center;
		padding: 0;
	}

	&__dropdown-li {
		display: block;
		width: 100%;
		margin-left: 0;
		border-radius: 0;
	}

	&__dropdown-link {
		padding: 5px 10px !important;
		color: rgba(255,255,255,0.7) !important;
		font-size: 1.2rem;
		transition: all 0.3s;
		.OswaldMedium;
		text-transform: uppercase;





		&:hover, &:focus, &.active {
			color: #fff !important;
			background-color: transparent !important;
		}
	}
}

.banner {
	.BannerPanelSet(59.76%);
	position: relative;
	background-image: url("@{cloudUrlNoAuto}banner-bg.jpg");
	background-size: 100% 100%;

	&__inner {
		padding-top: 7%;
		color: #fff;
	}

	&__bg {
		position: relative;
		z-index: 2;

		&:before {
			.StickToEdges;
			background: #e6e6e6;
			border: 3px solid #e6e6e6;
			transform: rotate(1.25deg);
			z-index: -1;
		}
	}

	#CarouselContainer {
		margin: 0 auto;
		margin-bottom: -7%;
		border: 3px solid #fff;
		position: relative;
		background: #fff;
	}

	&__content {

		@media @mobile {
			padding-bottom: 40px;
			text-align: center;
		}

		h2 {
			.Oswald;
			color: #FFF;
			text-transform: uppercase;

			@media @mobile {
				font-size: 1.2rem;
				text-align: center;
			}

			@media @tablet {
				font-size: 5.5vw;
			}

			@media @desktop {
				font-size: 3.75rem;
			}
		}

		p {
			.OpenSansemiBold;
			color: #FFF;
			font-size: 1.5rem;

			@media @mobile {
				text-align: center;
				font-size: 1.2rem;
			}
		}
	}
}

.book-online {
	@width: 2306px;
	position: relative;

	&__container {
		//background: #3d0064;
		padding: 100px 0 0;

		@media @mobile {
			padding: 0;
		}
	}

	&__title {
		position: relative;
		transform: translateY(~'calc(50% + 1px)');
		overflow: hidden;
	}

	&__text {
		padding: 0 15px;
		width: auto;
		display: inline-block;
		position: relative;
		line-height: 1;
		left: 7%;
		.OswaldMedium;
		font-size: 40px;
		color: #fff;

		&:before, &:after {
			content: "";
			position: absolute;
			width: 1200px;
			height: 1px;
			background: #364f8a;
			top: 50%;
		}

		&:before {
			right: 100%;
		}

		&:after {
			left: 100%;
		}
	}

	&__inner {
		padding: 15px;
		border-width: 0 1px 1px;
		border-style: solid;
		border-color: #364f8a;
		position: relative;
	}

	&__left {
		padding: 50px 2% 50px;
		width: 100%;

		@media @desktop {
			padding: 50px 2% 50px;
		}
	}




	.PostcodeMessage {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		transform: translateY(-80%);
		display: flex;
		justify-content: center;
		align-items: center;
		width: auto;
		min-width: 320px;
		right: auto;
	}

	#PostcodeSearchLeft select, #PostcodeSearchLeft input, .SearchDate, .SearchCategory {
		width: 23%;
		.TransformMiddleV;
		height: 50px;
		border-radius: 5px;
		padding-left: 2%;
		background-color: #f5f5f5;
		border: 2px solid transparent;
		background-repeat: no-repeat;
		background-position: right 10px center;
		background-size: 20px;
		padding-right: 35px;
		color: #555;
		transition: all 0.3s;
		box-shadow: none;


		&:focus {
			box-shadow: none;
			border: 2px solid #4d88ff;
		}
	}

	#PostcodeSearchLeft select, #PostcodeSearchLeft input {
		left: 5%;
		background-image: url("@{cloudUrl}icon-map-marker.svg");
	}



	.SearchDate {
		left: 30%;
		background-image: url("@{cloudUrl}icon-calendar.svg");
	}

	.SearchCategory {
		left: 55%;
		background-image: url("@{cloudUrl}icon-smile-o.svg");
		background-position: right 30px center;
		padding-right: 55px;
	}

	.SearchButton {
		position: absolute;
		.TransformMiddleV;
		.OswaldMedium;
		width: 15%;
		left: 80%;
		text-shadow: 0 1px #000;
		border-radius: 5px;
		color: #fff;
		height: 50px;
		.FlexCenter;
		font-size: 24px;
		.Gradient(#dc432c,#b61e07);
	}
}

.no-gutter {
	margin-left: 0;
	margin-right: 0;

	& > [class*='col-'] {
		padding-right: 0;
		padding-left: 0;
	}
}

.columns {
	display: flex;
	border-radius: 15px;
	position: relative;
	z-index: 1;
	@columnsWidth: 1368px;
	@leftColWidth: 318px;
	@rightColWidth: 1050px;
	@padding: 54px;

	@media @mobile {
		margin-top: 0;
	}

	&__wrapper {
		position: relative;
		background: linear-gradient(to bottom, #002680 0%,#132755 15%);
	}

	&__left {
		position: relative;
		width: round(percentage(@leftColWidth/@columnsWidth),2);
		margin-top: 15px;
	}

	&__container {
		padding-bottom: 15px;

		@media @tablet {
			padding-top: 50px;
		}
	}

	&__main {
		overflow: hidden;
		width: 100%;
		margin-bottom: 30px;


		@media @sm {
			width: round(percentage(@rightColWidth/@columnsWidth),2);
		}

		@media @tablet {
			padding-left: 25px;
		}

		@media @mobile {
			padding: 0 10px;
		}
	}
}

.category-links {
	margin-bottom: 10px;

	@media @tablet-only {
		margin-top: 21%;
	}

	&__link, &__child-link {
		position: relative;
		display: block;
		z-index: 1;
		overflow: hidden;
		margin: 10px 0;
		padding: 15px;
		text-align: left;
		line-height: 100%;
		color: #FFF;
		transition: all 0.6s;
		.PTSansNarrowBold;
		font-size: 1.2rem;
		background-color: #132755;
		border: 1px solid #2f3d5f;

		&:before {
			.StickToEdges;
			.Gradient(#3e75e3,#1e55c3);
			z-index: -1;
			opacity: 0;
			transition: all 0.3s;
		}

		&:hover, &.active, &:focus {
			color: #fff;

			&:before {
				opacity: 1;
			}
		}
	}

	&__child-link{
		margin-left:10px;
	}
}

.homepage-listings {
	@media @mobile {
		padding: 0 10px;
	}

	margin-bottom: 100px;

	&__wrapper {
		position: relative;
		.Gradient(#01267d,#17274d);
	}

	&__row {
		@media @mobile {
			margin-left: -8px;
			margin-right: -8px;

			& > div {
				padding-left: 8px;
				padding-right: 8px;
			}
		}

		@media (max-width:420px) {

			& > div {
				width: 100%;
			}
		}
	}
}

#PageListings, .PageListings, .premium-packages-listings {
	@media @mobile {
		padding: 0 10px;
	}

	@media (max-width:420px) {

		& > div {
			width: 100%;
		}
	}
}
/*#region Castle Panels*/
.castlePanel {
	width: 100%;
	padding: 5px 5px 10px;
	margin: 0 0 30px;
	text-align: center;
	transition: all 0.3s;
	position: relative;
	box-shadow: 0 0 0 rgba(0,0,0,0);
	display: flex;
	flex-direction: column;
	border: none;


	@media @mobile {
		text-align: center;
	}

	&:hover {
		box-shadow: 0 0 6px 0px rgba(0,0,0,.05);
	}

	.SingleImagePanel {
		background: transparent;
		order: 1;
		margin: 10px 0 0;
		position: relative;
		height: auto;
		padding-top: 0;

		&:before {
			content: "";
			width: 100%;
			padding-top: 85.83%;
			display: block;
		}

		img {
			top: 0 !important;
			height: 100%; // was asked for
		}
	}

	.castleTitlePanel {
		margin-top: 10px;
		padding: 0 5%;
		display: flex;
		justify-content: center;
		align-items: center;
		order: 2;
		.OswaldMedium;
		font-size: 1.125rem;

		a {
			color: #404040;
			position: relative;
		}
	}

	.castlePriceDefault, .castlePriceDiscounted {
		color: #000;
		position: relative;
		order: 3;
		padding: 0 5%;
		margin-top: 10px;
		//display: none;
		.Oswald;
		font-size: 1.125rem;

		span.oldprice {
			font-size: 0.8rem;
			color: #df1f1f;
		}

		span.newprice {
			font-size: 1rem;
		}
	}

	.castlePriceDefault {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.castleCheckBook {
		order: 4;
		.Gradient(#3e76e3,#2159c6);
		border-radius: 5px;
		position: relative;
		height: auto;
		padding: 10px;
		margin-top: 10px;
		text-transform: uppercase;
		.OswaldMedium;
		display: block;
		font-size: 1rem;
		margin-left: 5px;
		margin-right: 5px;
	}
}
/*#endregion Castle Panels*/
.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: auto;
		display: inline-block;
		margin: 20px 10px 10px;
		float: none;
		width: 195px;
	}
}

a.BackToCategory {
	box-shadow: none;
}

.newsletter {
	//display: flex;
	//justify-content: center;
	@media @mobile {
		display: none;
	}

	&__text {
		position: absolute;
		left: 0;
		top: 20%;

		@media @tablet-only {
			left: 33%;
			top: -65%;
		}
	}

	#NewsletterControl {
		display: flex;
		justify-content: flex-end;

		@media @tablet-only {
			justify-content: center;
		}

		@media @mobile {
			flex-direction: column;
			padding: 0 20px;
		}
	}

	&__wrapper {
		.Gradient(#db2f16,#ca1e05);
		position: relative;
		padding: 40px 0;

		@media @tablet-only {
			padding: 70px 0 20px;
		}

		@media @mobile {
			display: none;
		}
	}

	&__name, &__email, &__submit {
		height: 50px;
		margin: 15px 5px;
		border: none;
		border-radius: 5px;
		padding-left: 1.5%;
	}

	&__name {
		color: #000;

		@media @mobile {
			width: 100%;
			max-width: 420px;
			align-self: center;
		}

		@media @tablet {
			width: 300px;
		}
	}

	&__email {
		color: #000;

		@media @mobile {
			width: 100%;
			max-width: 420px;
			align-self: center;
		}

		@media @tablet {
			width: 350px;
		}
	}

	&__submit {
		.Gradient(#dc432c,#b71e08);
		.FlexCenter;
		color: #fff;
		text-transform: uppercase;
		padding-left: 0;
		.OswaldMedium;
		border: 1px solid transparent;
		transition: all 0.3s;
		font-size: 1.5rem;

		&:hover {
			color: #fff;
			border: 1px solid #fff;
		}

		@media @mobile {
			align-self: center;
			padding: 10px 15px;
		}

		@media @tablet {
			width: 160px;
		}
	}

	.clear {
		display: none;
		border-radius: 5px;
	}
}

.testimonials {
	background-color: #2a62d3;
	padding: 50px;
	margin-top: -100px;
	color: #3b3b3b;

	@media @mobile {
		padding: 25px 10px;
	}

	&__wrapper {
		position: relative;
		background-color: #FFF;

		@media @mobile {
			padding-bottom: 0;
		}
	}

	&__main {
		position: relative;
		width: 100%;
	}

	&__outer {
		position: relative;
		padding: 20px 120px;
		margin-bottom: 25px;

		@media @mobile {
			padding: 20px;
		}
	}

	.author, a, span {
		color: #b22424;
		.OswaldMedium;
		font-size: 1.25rem;
	}

	.carousel {
		background: #fff;
		padding: 40px;
		filter: drop-shadow(0 8px 16px rgba(0,0,0,.2));

		@media @mobile {
			padding: 25px 10px;
		}
	}

	.carousel-control.left, .carousel-control.right {
		top: 50%;
		bottom: auto;
		transform: translateX(40%) translateY(-50%);
		opacity: 1;
		width: 10%;
		background-image: none;
		z-index: 1;
		filter: drop-shadow( 0 2px 4px rgba(0,0,0,0.2));
		left: -3%;
	}

	.carousel-control.right {
		transform: translateX(-40%)translateY(-50%);
		right: -3%;
		left: auto;
	}

	.carousel-control.right img {
		transform: scaleX(-1);
	}


	time {
		display: none;
	}

	.RotatorTestimonialContent {
		display: flex;
		flex-direction: column-reverse;
		text-align: center;
		.OpenSans;
		font-style: italic;
		font-size: 1.1875rem;

		& > p:first-child {
			color: #fff;
		}
	}

	#RotatorTestimonialsHeader {
		margin-bottom: 15px;

		p, a {
			text-align: center;
			color: #fff;
			font-size: 1rem;
		}
	}

	.RotatorTestimonialRating {
		max-width: 280px;
		margin: 10px auto 0;
	}
}



.clientsTitle {
	margin-bottom: -45px;
}

.clients {
	display: flex;
	justify-content: space-around;
	border: 1px solid #c4c4c4;
	border-radius: 5px;
	padding: 50px;

	@media @mobile {
		flex-wrap: wrap;
	}

	&__wrapper {
		background: #fff;
	}

	&__title {
		transform: translateY(50%);
	}

	&__outer {
		background: #F5F5F5;
		margin-left: -15px;
		margin-right: -15px;
		padding: 0 20px 20px;
	}

	&__inner {
		border-radius: 15px;
		border: 3px solid #f2f2f2;
	}

	&__gallery {
		width: 22%;
		position: relative;

		@media @mobile {
			width: 48%;
		}
	}

	.carousel-inner {
		.FlexCenter;
	}

	&__column {
		margin-top: 6%;
	}

	.item {
		width: 100% !important;
		height: 100% !important;
	}

	&__image-wrapper {
		position: relative;

		&:before {
			content: "";
			display: block;
			width: 100%;
			padding-top: 100%;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}

.footer {
	&__wrapper {
		background: #000;
		color: #fff;
		padding: 25px 10px;
		width: 100%;
		z-index: 1;
		position: relative;

		@media @mobile {
			text-align: center;
		}
	}

	.flex-row {
		@media @sm {
			display: flex;
			align-items: center;
		}
	}

	&__links {
		text-align: center;

		@media @mobile {
			text-align: center;
			margin-top: 15px;
		}

		a {
			color: #b3b3b3;
			margin-right: 10px;
		}
	}

	p {
		color: #b3b3b3;
	}

	&__BCN {
		display: block;
		max-width: 180px;

		@media @mobile {
			margin-top: 10px;
			text-align: center;
			display: inline-block;
		}

		@media @sm {
			float: right;
		}

		a {
			color: #a1a1a1;

			&:hover {
				color: #fff;
			}
		}
	}
}

#BouncyCastleHireLink, .bch-link {
	color: #a1a1a1;
	text-align: center;
}
/*#region Animations*/
.wow {
	visibility: hidden;
}


/*#endregion Animations*/


.product {
	background: #fff;
	color: #000;
	border-radius: 10px;
}

table.extraAssets {
	color: #000;
}

.TestimonialText {
	color: #222;
}

.textContent a {
	color: #88c2f5;
	transition: all 0.3s;

	&:hover {
		color: darken(#88c2f5,10%);
	}
}

.PriceTable {
	tr {
		background: transparent !important;
	}
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}

@keyframes zoomIn {
	0% {
		opacity: 0;
		transform: scale3d(.3,.3,.3)
	}

	50% {
		opacity: 1
	}
}

.zoomIn {
	animation-name: zoomIn
}
