/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region Fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.OpenSansemiBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.Montserrat {
  font-weight: 400 !important;
  font-family: 'Montserrat', sans-serif;
}
.MontserratBold {
  font-weight: 700 !important;
  font-family: 'Montserrat', sans-serif;
}
.Oswald {
  font-family: 'Oswald', sans-serif;
  font-weight: 400 !important;
}
.OswaldMedium {
  font-family: 'Oswald', sans-serif;
  font-weight: 500 !important;
}
.PTSansNarrowBold {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
}
html {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
}
body {
  overflow-x: hidden;
  background: #141d33;
}
.textContent {
  font-size: 1rem;
  color: #FFF;
}
@media (max-width: 767px) {
  .textContent {
    text-align: center;
  }
}
.textContent h1 {
  font-size: 2.5rem;
  color: #FFF;
  font-family: 'Oswald', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  text-align: center;
}
.lower-content--homepage {
  margin-top: -80px;
  margin-bottom: 80px;
}
.section-title--h2 {
  font-size: 2.5rem;
  color: #FFF;
  font-family: 'Oswald', sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
}
.section-title--h3 {
  font-weight: 400 !important;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.125rem;
}
.section-title--newsletter {
  font-size: 1.8rem;
  font-family: 'Oswald', sans-serif;
  font-weight: 400 !important;
  color: #FFF;
}
.section-title--testimonials,
.section-title--booth {
  color: #FFF;
}
.section-title--products-intro {
  text-align: center;
}
/*#endregion Fonts*/
#BodyContent {
  padding-top: 20px;
}
@media (max-width: 767px) {
  #BodyContent {
    padding-top: 0;
  }
}
.nicefont,
h1,
h2,
h3 {
  font-weight: 700 !important;
  font-family: 'Montserrat', sans-serif;
}
form {
  margin: 0 auto;
  position: relative;
}
.outer-wrapper {
  max-width: 100vw;
  position: relative;
}
@media (max-width: 767px) {
  .outer-wrapper {
    overflow: hidden;
  }
}
.outer-wrapper.outer-wrapper--alt .top-wrapper {
  background: transparent;
}
.outer-wrapper.outer-wrapper--alt .columns {
  margin-top: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.outer-wrapper.outer-wrapper--alt .columns__main {
  background: #fff;
  border-radius: 15px;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .outer-wrapper.outer-wrapper--alt .columns__main {
    width: 74.75%;
  }
}
.outer-wrapper.outer-wrapper--alt .columns__container {
  padding-top: 10px;
  min-height: 510px;
}
.outer-wrapper.outer-wrapper--alt .textContent {
  color: #333 !important;
}
.outer-wrapper.outer-wrapper--alt .textContent h1 {
  color: #141D33 !important;
}
.outer-wrapper.outer-wrapper--alt .textContent span {
  color: #333 !important;
}
.outer-wrapper.outer-wrapper--alt .lower-content h2 {
  color: #141D33 !important;
}
@media (min-width: 768px) {
  .outer-wrapper.outer-wrapper--alt #BodyContent {
    padding-bottom: 50px;
  }
}
.container {
  position: relative;
}
@media (max-width: 767px) {
  .container {
    margin: 0 auto 0;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
    padding: 0 10px;
    margin: 0 auto 0;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
    margin: 0 auto 0;
  }
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
  padding-top: 53px;
}
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #B62F16;
  background-color: #e2533e;
  background: -webkit-gradient(linear, left top, left bottom, from(#e2533e), to(#c91e05));
  background: linear-gradient(to bottom, #e2533e, #c91e05);
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  min-height: 5px;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: block !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0% 0%;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.top-wrapper {
  position: relative;
}
.header-wrapper {
  background-color: #141d33;
}
.header {
  text-align: center;
  position: relative;
  background-color: #141d33;
}
@media (max-width: 767px) {
  .header {
    padding: 20px 10px;
  }
}
@media (min-width: 768px) {
  .header {
    padding-top: 17%;
  }
}
.header__container {
  padding: 0;
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    width: 28.93%;
    bottom: -22.52%;
    left: 0.42%;
  }
}
@media (max-width: 767px) {
  .header__logo {
    display: block;
    margin: 10px auto 20px;
    text-align: center;
    max-width: 80% !important;
  }
}
.header__email {
  color: #fff;
  font-family: 'Oswald', sans-serif;
  font-weight: 400 !important;
  line-height: 1;
  font-size: 20px;
}
.header__email:before {
  margin-right: 15px;
  color: #3369e1;
  content: "\f0e0";
  font-family: FontAwesome;
}
.header__email:hover {
  color: #fff;
}
@media (max-width: 767px) {
  .header__email {
    display: block;
    margin: 10px auto;
  }
}
@media (min-width: 768px) {
  .header__email {
    position: absolute;
    bottom: 22.52%;
    left: 40%;
    text-align: left;
    font-size: 30px;
  }
}
@media (min-width: 1200px) {
  .header__email {
    font-size: 30px;
  }
}
.header__phone,
.header__phone-2 {
  color: #fff;
  font-family: 'Oswald', sans-serif;
  font-weight: 400 !important;
  line-height: 1;
  font-size: 30px;
}
.header__phone:before,
.header__phone-2:before {
  margin-right: 15px;
  color: #3369e1;
  content: "\f095";
  font-family: FontAwesome;
}
.header__phone:hover,
.header__phone-2:hover {
  color: #fff;
}
@media (max-width: 767px) {
  .header__phone,
  .header__phone-2 {
    display: block;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .header__phone,
  .header__phone-2 {
    position: absolute;
    bottom: 51.8%;
    left: 40%;
    text-align: left;
  }
}
.header .fa-phone {
  color: #3369e1;
  margin-right: 12px;
}
@media (max-width: 767px) {
  .header .fa-phone {
    display: none;
  }
}
.header .fa-envelope {
  color: #3369e1;
  margin-right: 10px;
}
@media (max-width: 767px) {
  .header .fa-envelope {
    display: none;
  }
}
.header__social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 767px) {
  .header__social-links {
    margin: 20px auto 10px;
    max-width: 270px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (min-width: 768px) {
  .header__social-links {
    position: absolute;
    width: 15%;
    bottom: -9%;
    right: auto;
    left: 39.5%;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
@media (min-width: 992px) {
  .header__social-links {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    width: 14%;
    bottom: 45%;
    right: 0%;
    left: auto;
  }
}
@media (min-width: 768px) {
  .header__social-link {
    width: 24%;
  }
}
@media (min-width: 768px) {
  .header__social-link {
    margin: 0 2%;
  }
}
@media (max-width: 767px) {
  .header__social-link {
    margin: 0 5px;
    max-width: 55px;
  }
}
@media (min-width: 768px) {
  .header .header__pipa {
    position: absolute;
    top: 50%;
    width: 8%;
    right: 0;
  }
}
.toplinks {
  position: relative;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
  padding: 10px 0;
}
.toplinks__wrapper {
  background-color: #e2533e;
  background: -webkit-gradient(linear, left top, left bottom, from(#e2533e), to(#c91e05));
  background: linear-gradient(to bottom, #e2533e, #c91e05);
}
@media (min-width: 768px) {
  .toplinks__wrapper {
    margin-top: 40px;
  }
}
@media (min-width: 992px) {
  .toplinks__wrapper {
    margin-top: 0;
  }
}
.toplinks__container {
  padding: 0;
}
.toplinks__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 30%;
}
.toplinks__li {
  text-align: center;
  list-style: none;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 0;
}
.toplinks__li.open .dropdown-toggle:focus {
  color: #fff;
}
.toplinks__link {
  color: rgba(255, 255, 255, 0.7);
  background: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  white-space: nowrap;
  display: block;
  padding: 10px 5px;
  position: relative;
  z-index: 1;
  font-size: 1.2rem;
  font-family: 'Oswald', sans-serif;
  font-weight: 500 !important;
  text-transform: uppercase;
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #FFF;
}
.toplinks__dropdown,
.toplinks .dropdown-menu {
  background-color: #e2533e;
  background: -webkit-gradient(linear, left top, left bottom, from(#e2533e), to(#c91e05));
  background: linear-gradient(to bottom, #e2533e, #c91e05);
  text-align: center;
  padding: 0;
}
.toplinks__dropdown-li {
  display: block;
  width: 100%;
  margin-left: 0;
  border-radius: 0;
}
.toplinks__dropdown-link {
  padding: 5px 10px !important;
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 1.2rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-family: 'Oswald', sans-serif;
  font-weight: 500 !important;
  text-transform: uppercase;
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  color: #fff !important;
  background-color: transparent !important;
}
.banner {
  position: relative;
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/015/banner-bg.jpg");
  background-size: 100% 100%;
}
.banner #CarouselContainer {
  padding-top: 59.76%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.banner__inner {
  padding-top: 7%;
  color: #fff;
}
.banner__bg {
  position: relative;
  z-index: 2;
}
.banner__bg:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background: #e6e6e6;
  border: 3px solid #e6e6e6;
  -webkit-transform: rotate(1.25deg);
          transform: rotate(1.25deg);
  z-index: -1;
}
.banner #CarouselContainer {
  margin: 0 auto;
  margin-bottom: -7%;
  border: 3px solid #fff;
  position: relative;
  background: #fff;
}
@media (max-width: 767px) {
  .banner__content {
    padding-bottom: 40px;
    text-align: center;
  }
}
.banner__content h2 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400 !important;
  color: #FFF;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .banner__content h2 {
    font-size: 1.2rem;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .banner__content h2 {
    font-size: 5.5vw;
  }
}
@media (min-width: 1200px) {
  .banner__content h2 {
    font-size: 3.75rem;
  }
}
.banner__content p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #FFF;
  font-size: 1.5rem;
}
@media (max-width: 767px) {
  .banner__content p {
    text-align: center;
    font-size: 1.2rem;
  }
}
.book-online {
  position: relative;
}
.book-online__container {
  padding: 100px 0 0;
}
@media (max-width: 767px) {
  .book-online__container {
    padding: 0;
  }
}
.book-online__title {
  position: relative;
  -webkit-transform: translateY(calc(50% + 1px));
          transform: translateY(calc(50% + 1px));
  overflow: hidden;
}
.book-online__text {
  padding: 0 15px;
  width: auto;
  display: inline-block;
  position: relative;
  line-height: 1;
  left: 7%;
  font-family: 'Oswald', sans-serif;
  font-weight: 500 !important;
  font-size: 40px;
  color: #fff;
}
.book-online__text:before,
.book-online__text:after {
  content: "";
  position: absolute;
  width: 1200px;
  height: 1px;
  background: #364f8a;
  top: 50%;
}
.book-online__text:before {
  right: 100%;
}
.book-online__text:after {
  left: 100%;
}
.book-online__inner {
  padding: 15px;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: #364f8a;
  position: relative;
}
.book-online__left {
  padding: 50px 2% 50px;
  width: 100%;
}
@media (min-width: 1200px) {
  .book-online__left {
    padding: 50px 2% 50px;
  }
}
.book-online .PostcodeMessage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateY(-80%);
          transform: translateY(-80%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: auto;
  min-width: 320px;
  right: auto;
}
.book-online #PostcodeSearchLeft select,
.book-online #PostcodeSearchLeft input,
.book-online .SearchDate,
.book-online .SearchCategory {
  width: 23%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 50px;
  border-radius: 5px;
  padding-left: 2%;
  background-color: #f5f5f5;
  border: 2px solid transparent;
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
  padding-right: 35px;
  color: #555;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.book-online #PostcodeSearchLeft select:focus,
.book-online #PostcodeSearchLeft input:focus,
.book-online .SearchDate:focus,
.book-online .SearchCategory:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 2px solid #4d88ff;
}
.book-online #PostcodeSearchLeft select,
.book-online #PostcodeSearchLeft input {
  left: 5%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/015/icon-map-marker.svg");
}
.book-online .SearchDate {
  left: 30%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/015/icon-calendar.svg");
}
.book-online .SearchCategory {
  left: 55%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/015/icon-smile-o.svg");
  background-position: right 30px center;
  padding-right: 55px;
}
.book-online .SearchButton {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: 'Oswald', sans-serif;
  font-weight: 500 !important;
  width: 15%;
  left: 80%;
  text-shadow: 0 1px #000;
  border-radius: 5px;
  color: #fff;
  height: 50px;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 24px;
  background-color: #dc432c;
  background: -webkit-gradient(linear, left top, left bottom, from(#dc432c), to(#b61e07));
  background: linear-gradient(to bottom, #dc432c, #b61e07);
}
.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 15px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .columns {
    margin-top: 0;
  }
}
.columns__wrapper {
  position: relative;
  background: -webkit-gradient(linear, left top, left bottom, from(#002680), color-stop(15%, #132755));
  background: linear-gradient(to bottom, #002680 0%, #132755 15%);
}
.columns__left {
  position: relative;
  width: 23.25%;
  margin-top: 15px;
}
.columns__container {
  padding-bottom: 15px;
}
@media (min-width: 768px) {
  .columns__container {
    padding-top: 50px;
  }
}
.columns__main {
  overflow: hidden;
  width: 100%;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .columns__main {
    width: 76.75%;
  }
}
@media (min-width: 768px) {
  .columns__main {
    padding-left: 25px;
  }
}
@media (max-width: 767px) {
  .columns__main {
    padding: 0 10px;
  }
}
.category-links {
  margin-bottom: 10px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .category-links {
    margin-top: 21%;
  }
}
.category-links__link,
.category-links__child-link {
  position: relative;
  display: block;
  z-index: 1;
  overflow: hidden;
  margin: 10px 0;
  padding: 15px;
  text-align: left;
  line-height: 100%;
  color: #FFF;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
  font-size: 1.2rem;
  background-color: #132755;
  border: 1px solid #2f3d5f;
}
.category-links__link:before,
.category-links__child-link:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #3e75e3;
  background: -webkit-gradient(linear, left top, left bottom, from(#3e75e3), to(#1e55c3));
  background: linear-gradient(to bottom, #3e75e3, #1e55c3);
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.category-links__link:hover,
.category-links__child-link:hover,
.category-links__link.active,
.category-links__child-link.active,
.category-links__link:focus,
.category-links__child-link:focus {
  color: #fff;
}
.category-links__link:hover:before,
.category-links__child-link:hover:before,
.category-links__link.active:before,
.category-links__child-link.active:before,
.category-links__link:focus:before,
.category-links__child-link:focus:before {
  opacity: 1;
}
.category-links__child-link {
  margin-left: 10px;
}
.homepage-listings {
  margin-bottom: 100px;
}
@media (max-width: 767px) {
  .homepage-listings {
    padding: 0 10px;
  }
}
.homepage-listings__wrapper {
  position: relative;
  background-color: #01267d;
  background: -webkit-gradient(linear, left top, left bottom, from(#01267d), to(#17274d));
  background: linear-gradient(to bottom, #01267d, #17274d);
}
@media (max-width: 767px) {
  .homepage-listings__row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .homepage-listings__row > div {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (max-width: 420px) {
  .homepage-listings__row > div {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #PageListings,
  .PageListings,
  .premium-packages-listings {
    padding: 0 10px;
  }
}
@media (max-width: 420px) {
  #PageListings > div,
  .PageListings > div,
  .premium-packages-listings > div {
    width: 100%;
  }
}
/*#region Castle Panels*/
.castlePanel {
  width: 100%;
  padding: 5px 5px 10px;
  margin: 0 0 30px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: none;
}
@media (max-width: 767px) {
  .castlePanel {
    text-align: center;
  }
}
.castlePanel:hover {
  -webkit-box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.05);
}
.castlePanel .SingleImagePanel {
  background: transparent;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  margin: 10px 0 0;
  position: relative;
  height: auto;
  padding-top: 0;
}
.castlePanel .SingleImagePanel:before {
  content: "";
  width: 100%;
  padding-top: 85.83%;
  display: block;
}
.castlePanel .SingleImagePanel img {
  top: 0 !important;
  height: 100%;
}
.castlePanel .castleTitlePanel {
  margin-top: 10px;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  font-family: 'Oswald', sans-serif;
  font-weight: 500 !important;
  font-size: 1.125rem;
}
.castlePanel .castleTitlePanel a {
  color: #404040;
  position: relative;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  color: #000;
  position: relative;
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
  padding: 0 5%;
  margin-top: 10px;
  font-family: 'Oswald', sans-serif;
  font-weight: 400 !important;
  font-size: 1.125rem;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  font-size: 0.8rem;
  color: #df1f1f;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 1rem;
}
.castlePanel .castlePriceDefault {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.castlePanel .castleCheckBook {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
  background-color: #3e76e3;
  background: -webkit-gradient(linear, left top, left bottom, from(#3e76e3), to(#2159c6));
  background: linear-gradient(to bottom, #3e76e3, #2159c6);
  border-radius: 5px;
  position: relative;
  height: auto;
  padding: 10px;
  margin-top: 10px;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  font-weight: 500 !important;
  display: block;
  font-size: 1rem;
  margin-left: 5px;
  margin-right: 5px;
}
/*#endregion Castle Panels*/
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #c91d04;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: auto;
  display: inline-block;
  margin: 20px 10px 10px;
  float: none;
  width: 195px;
}
a.BackToCategory {
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (max-width: 767px) {
  .newsletter {
    display: none;
  }
}
.newsletter__text {
  position: absolute;
  left: 0;
  top: 20%;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .newsletter__text {
    left: 33%;
    top: -65%;
  }
}
.newsletter #NewsletterControl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .newsletter #NewsletterControl {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 767px) {
  .newsletter #NewsletterControl {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0 20px;
  }
}
.newsletter__wrapper {
  background-color: #db2f16;
  background: -webkit-gradient(linear, left top, left bottom, from(#db2f16), to(#ca1e05));
  background: linear-gradient(to bottom, #db2f16, #ca1e05);
  position: relative;
  padding: 40px 0;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .newsletter__wrapper {
    padding: 70px 0 20px;
  }
}
@media (max-width: 767px) {
  .newsletter__wrapper {
    display: none;
  }
}
.newsletter__name,
.newsletter__email,
.newsletter__submit {
  height: 50px;
  margin: 15px 5px;
  border: none;
  border-radius: 5px;
  padding-left: 1.5%;
}
.newsletter__name {
  color: #000;
}
@media (max-width: 767px) {
  .newsletter__name {
    width: 100%;
    max-width: 420px;
    -ms-flex-item-align: center;
        align-self: center;
  }
}
@media (min-width: 768px) {
  .newsletter__name {
    width: 300px;
  }
}
.newsletter__email {
  color: #000;
}
@media (max-width: 767px) {
  .newsletter__email {
    width: 100%;
    max-width: 420px;
    -ms-flex-item-align: center;
        align-self: center;
  }
}
@media (min-width: 768px) {
  .newsletter__email {
    width: 350px;
  }
}
.newsletter__submit {
  background-color: #dc432c;
  background: -webkit-gradient(linear, left top, left bottom, from(#dc432c), to(#b71e08));
  background: linear-gradient(to bottom, #dc432c, #b71e08);
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  text-transform: uppercase;
  padding-left: 0;
  font-family: 'Oswald', sans-serif;
  font-weight: 500 !important;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 1.5rem;
}
.newsletter__submit:hover {
  color: #fff;
  border: 1px solid #fff;
}
@media (max-width: 767px) {
  .newsletter__submit {
    -ms-flex-item-align: center;
        align-self: center;
    padding: 10px 15px;
  }
}
@media (min-width: 768px) {
  .newsletter__submit {
    width: 160px;
  }
}
.newsletter .clear {
  display: none;
  border-radius: 5px;
}
.testimonials {
  background-color: #2a62d3;
  padding: 50px;
  margin-top: -100px;
  color: #3b3b3b;
}
@media (max-width: 767px) {
  .testimonials {
    padding: 25px 10px;
  }
}
.testimonials__wrapper {
  position: relative;
  background-color: #FFF;
}
@media (max-width: 767px) {
  .testimonials__wrapper {
    padding-bottom: 0;
  }
}
.testimonials__main {
  position: relative;
  width: 100%;
}
.testimonials__outer {
  position: relative;
  padding: 20px 120px;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .testimonials__outer {
    padding: 20px;
  }
}
.testimonials .author,
.testimonials a,
.testimonials span {
  color: #b22424;
  font-family: 'Oswald', sans-serif;
  font-weight: 500 !important;
  font-size: 1.25rem;
}
.testimonials .carousel {
  background: #fff;
  padding: 40px;
  -webkit-filter: drop-shadow(0 8px 16px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 8px 16px rgba(0, 0, 0, 0.2));
}
@media (max-width: 767px) {
  .testimonials .carousel {
    padding: 25px 10px;
  }
}
.testimonials .carousel-control.left,
.testimonials .carousel-control.right {
  top: 50%;
  bottom: auto;
  -webkit-transform: translateX(40%) translateY(-50%);
          transform: translateX(40%) translateY(-50%);
  opacity: 1;
  width: 10%;
  background-image: none;
  z-index: 1;
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  left: -3%;
}
.testimonials .carousel-control.right {
  -webkit-transform: translateX(-40%) translateY(-50%);
          transform: translateX(-40%) translateY(-50%);
  right: -3%;
  left: auto;
}
.testimonials .carousel-control.right img {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.testimonials time {
  display: none;
}
.testimonials .RotatorTestimonialContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1.1875rem;
}
.testimonials .RotatorTestimonialContent > p:first-child {
  color: #fff;
}
.testimonials #RotatorTestimonialsHeader {
  margin-bottom: 15px;
}
.testimonials #RotatorTestimonialsHeader p,
.testimonials #RotatorTestimonialsHeader a {
  text-align: center;
  color: #fff;
  font-size: 1rem;
}
.testimonials .RotatorTestimonialRating {
  max-width: 280px;
  margin: 10px auto 0;
}
.clientsTitle {
  margin-bottom: -45px;
}
.clients {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 50px;
}
@media (max-width: 767px) {
  .clients {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.clients__wrapper {
  background: #fff;
}
.clients__title {
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}
.clients__outer {
  background: #F5F5F5;
  margin-left: -15px;
  margin-right: -15px;
  padding: 0 20px 20px;
}
.clients__inner {
  border-radius: 15px;
  border: 3px solid #f2f2f2;
}
.clients__gallery {
  width: 22%;
  position: relative;
}
@media (max-width: 767px) {
  .clients__gallery {
    width: 48%;
  }
}
.clients .carousel-inner {
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.clients__column {
  margin-top: 6%;
}
.clients .item {
  width: 100% !important;
  height: 100% !important;
}
.clients__image-wrapper {
  position: relative;
}
.clients__image-wrapper:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}
.clients__image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.footer__wrapper {
  background: #000;
  color: #fff;
  padding: 25px 10px;
  width: 100%;
  z-index: 1;
  position: relative;
}
@media (max-width: 767px) {
  .footer__wrapper {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer .flex-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.footer__links {
  text-align: center;
}
@media (max-width: 767px) {
  .footer__links {
    text-align: center;
    margin-top: 15px;
  }
}
.footer__links a {
  color: #b3b3b3;
  margin-right: 10px;
}
.footer p {
  color: #b3b3b3;
}
.footer__BCN {
  display: block;
  max-width: 180px;
}
@media (max-width: 767px) {
  .footer__BCN {
    margin-top: 10px;
    text-align: center;
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .footer__BCN {
    float: right;
  }
}
.footer__BCN a {
  color: #a1a1a1;
}
.footer__BCN a:hover {
  color: #fff;
}
#BouncyCastleHireLink,
.bch-link {
  color: #a1a1a1;
  text-align: center;
}
/*#region Animations*/
.wow {
  visibility: hidden;
}
/*#endregion Animations*/
.product {
  background: #fff;
  color: #000;
  border-radius: 10px;
}
table.extraAssets {
  color: #000;
}
.TestimonialText {
  color: #222;
}
.textContent a {
  color: #88c2f5;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.textContent a:hover {
  color: #59aaf1;
}
.PriceTable tr {
  background: transparent !important;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  -webkit-animation-name: zoomIn;
          animation-name: zoomIn;
}
